exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-burger-js": () => import("./../../../src/pages/burger.js" /* webpackChunkName: "component---src-pages-burger-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-2-strokes-js": () => import("./../../../src/pages/home2strokes.js" /* webpackChunkName: "component---src-pages-home-2-strokes-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-projects-all-js": () => import("./../../../src/pages/projects/all.js" /* webpackChunkName: "component---src-pages-projects-all-js" */),
  "component---src-pages-projects-copy-js": () => import("./../../../src/pages/projects copy.js" /* webpackChunkName: "component---src-pages-projects-copy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-mvps-js": () => import("./../../../src/pages/projects/mvps.js" /* webpackChunkName: "component---src-pages-projects-mvps-js" */),
  "component---src-pages-projects-real-blend-js": () => import("./../../../src/pages/projects/real-blend.js" /* webpackChunkName: "component---src-pages-projects-real-blend-js" */),
  "component---src-pages-random-js": () => import("./../../../src/pages/random.js" /* webpackChunkName: "component---src-pages-random-js" */),
  "component---src-pages-real-recaptcha-js": () => import("./../../../src/pages/real-recaptcha.js" /* webpackChunkName: "component---src-pages-real-recaptcha-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/Welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

