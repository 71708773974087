import './src/styles/projects.css'
import "./src/styles/manifesto.css"
import './src/styles/global.css'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import('intersection-observer').then(() => {
      console.log('IntersectionObserver polyfill loaded')
    })
  }

  // Force reload of styles
  if (typeof window !== `undefined`) {
    window.onload = () => {
      const styles = document.querySelectorAll('style[data-href]')
      styles.forEach(style => {
        style.setAttribute('href', style.getAttribute('data-href'))
        style.removeAttribute('data-href')
      })
    }
  }
}
